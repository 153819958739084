import React, {Component} from 'react';
import PropTypes, { func } from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button,Toolbar,FormLabel,FormGroup,Switch,Select,FormControlLabel, FormControl, Chip} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';

import classNames from 'classnames';



// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import "froala-editor/js/third_party/ionicons.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
import "froala-editor/css/third_party/font_awesome.min.css";
import "froala-editor/css/third_party/ionicons.min.css";

import FroalaEditor from "react-froala-wysiwyg";

import ImageControl from './shares/react_image_control';
import TabContainer from './shares/react_tab_container';
import ReactAutoSuggest from './shares/react_auto_suggest';

import language from '../layout/language';
import Utils from '../api/api';
import {styles} from '../layout/theme';

const calendar = [0,1,2,3,4,5,6];

class CompanyDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      _id:'',
      company: {
        name: '',
        logo: '',
        address: '',
        national: 'VN',
        size: '',
        workingDay: '1,5',
        summaryVN: '',
        summaryEN: '',
        detailVN: '',
        detailEN: '',
        typeVN: '',
        typeEN: '',
        url: '',
        isOT: false,
        employer: '',
        isPartner: false,
      },
      error: {
        vn: false,
        en: false,
        summaryVN_error: false,
        summaryEN_error: false,
        detailVN_error: false,
        detailEN_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false,
      options: {'empty':'empty'},
      employers: []
    };
  }
  componentDidMount(){
    const $this = this;
    const params = this.context.router.route.match.params;

    if (params.id !== undefined) {
      $this.setState({isUpdate: true},() => {
        
        Utils.getSingleData('company', params.id,(res) => {
          if (res.status === 'success' && res.results !== undefined) {
            const data = {},
                  item = res.results;
            for (let property in item) {
              //console.log(property);

              if (item.hasOwnProperty(property)) {
                data[property] = item[property];
              }
            }

            if (data.url === undefined) {
              data.url = '';
            }

            if (data.workingDay === undefined || data.workingDay.indexOf(',') === -1) {
              data.workingDay = '1,5';
            }

            if (data.isOT === undefined) {
              data.isOT = false;
            }
            
            if (data.employer === undefined) {
              data.employer = [];
            }

            if (data.isPartner === undefined) {
              data.isPartner = false;
            }

            $this.setState({company: data});
          }
        });
      });
    }

    Utils.getBlockContents(function(options){
      $this.setState({options:options});
    });

    Utils.getListDataSortWithFilter("accounts", 1, 0, "0,0", JSON.stringify({isEmployer:true}), null, 1, false, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ employers: data.results });
      }
    });
  }

  onEditorStateChange(field, value) {
    var company = this.state.company,
        error = this.state.error;
    company[field] = value;
    error[field + "_error"] = value === "";

    this.setState({ company: company, error: error });
  }

  handleDayChange(e,index) {
    var $this = this,
        company = this.state.company,
        arr = company.workingDay.split(',');

    arr[index] = e.target.value;
    company.workingDay = arr.join(',');

    this.setState({company:company},function(){
      console.log($this.state.company);
    });
  }

  handleFieldChange = (e) => {
    var company = this.state.company;
    company[e.target.id] = e.target.value;
    if(e.target.type==='checkbox') {
      company[e.target.id] = e.target.checked;
    }
    if(e.target.id==='name'){
      var url = Utils.getFriendlyURL(e.target.value);
      company.url = url;
    }
    if (e.target.type === "textarea") {
      company[e.target.id] = e.target.value.substring(0, 200);
    }
    this.setState({ company: company });
  }

  handleValidate (e,flag){
    const $this = this,
          company = this.state.company,
          obj = this.state.error;

    company[e.target.id] = e.target.value;
    var p = e.target.id + '_error';
    if (e.target.value.trim() === '' || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    this.setState({company: company, error:obj}, () => {
      if(!$this.state.error.summaryVN_error||!$this.state.error.detailVN_error){
        obj['vn'] = false;
      }else{
        obj['vn'] = true;
      }
      if(!$this.state.error.summaryEN_error||!$this.state.error.detailEN_error){
        obj['en'] = false;
      }else{
        obj['en'] = true;
      }
      $this.setState({error:obj});
    });
  }

  _save(){
    var $this = this;
    var data = this.state.company;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('company',data, function(res) {
        //console.log(res);
        if (res.status === 'success') {
          $this.context.router.history.push('/company/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('company',data, function(res) {

        if (res.status === 'success') {
          $this.context.router.history.push('/company/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }

  handleSave(e,flag){
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj["summaryVN_error"] = this.state.company.summaryVN === "";
          obj["detailVN_error"] = this.state.company.detailVN === "";
          obj["vn"] =
            obj["summaryVN_error"] ||
            obj["detailVN_error"];
          this.setState({ error: obj }, function() {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj["summaryEN_error"] = this.state.company.summaryEN === "";
          obj["detailEN_error"] = this.state.company.detailEN === "";
          obj["en"] =
            obj["summaryEN_error"] ||
            obj["detailEN_error"];
          this.setState({ error: obj }, function() {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['nameKR_error'] = this.state.category.nameKR === '';
        //   obj['kr'] = obj['nameKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel(){
    this.context.router.history.push('/company/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  selectImg(value){
    var $this = this,
        obj = this.state.category;
    obj.image = value;
    this.setState({category:obj},function(){
      $this.handleClose();
    });
  }
  handleLogoChange(value) {
    var company = this.state.company;
    company.logo = value;
    this.setState({company:company});
  }
  handleCopy() {
    var data = this.state.company;
    data.typeEN = data.typeVN;
    data.summaryEN = data.summaryVN;
    data.detailEN = data.detailVN;
    this.setState({
      company: data
    });
  }

  onUpdateEmployer(value) {
    let c = this.state.company,
      arr = c.employer;

    if(arr.indexOf(value)<0){
      arr.push(value);
      c.employer = arr;
      this.setState({company:c});
    }
  }

  render(){
    const $this = this,
          classes = this.props.classes,
          value = this.state.tab,
          company = this.state.company;

    const editorConfigBasic = Utils.setupBasicEditor();
    const editorConfig = Utils.setupEditor('others',this.state.options);

    const arr = [];

    $this.state.employers.map((x,i)=>{
      arr.push({title:x.email,value:x.email});
    });

    const employers = [];
    if (company.employer !== undefined && company.employer !== null && company.employer.length > 0) {
      company.employer.map((item, index) => {
        //console.log(item);
        $this.state.employers.map((y,z)=>{
          if (item === y._id) {
            //console.log(y._id);
            employers.push(
              <Chip
                label={y['email']}
                key={z}
                onDelete={() => this.handleDeleteLecturer(item, index)}
                className={classes.chip}
              />
            )
          }
        })
      });
    }

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Thêm/Sửa Công ty
          </Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.vn && classes.hide
                )}
              >
                {language.VN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                aria-label="copy"
                onClick={() => this.handleCopy()}
              >
                Copy to [EN]
              </Button>
              <TextField
                id="name"
                label="Tên công ty"
                className={classes.textField}
                value={company.name}
                onChange={event => this.handleFieldChange(event)}
                onBlur={event => this.handleFieldChange(event)}
                required={true}
                margin="normal"
              />
              <TextField
                id="url"
                label="Link [SEO]"
                className={classes.textField}
                value={company.url}
                disabled={true}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              <FormGroup>
                <Typography className={classes.label}>Tài khoản</Typography>
                <FormControl className={classes.chipRow}>
                  {
                    employers
                  }
                </FormControl>
                <ReactAutoSuggest className={classes.formRow} data={arr} onChange={(v)=>this.onUpdateEmployer(v)}></ReactAutoSuggest>
              </FormGroup>
              <FormGroup className={classes.formRow}>
                <ImageControl label="Logo" value={company.logo} handleChange={(value) => this.handleLogoChange(value)}></ImageControl>
              </FormGroup>
              <FormGroup>
                <Select id="national" native value={this.state.company.national} onChange={event => this.handleFieldChange(event)}>
                  <option value="AF">Afghanistan</option>
                  <option value="AX">Åland Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia, Plurinational State of</option>
                  <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD">Congo, the Democratic Republic of the</option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Côte d'Ivoire</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curaçao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and McDonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP">Korea, Democratic People's Republic of</option>
                  <option value="KR">Korea, Republic of</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia, Federated States of</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Réunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="BL">Saint Barthélemy</option>
                  <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin (French part)</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten (Dutch part)</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">South Georgia and the South Sandwich Islands</option>
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM">United States Minor Outlying Islands</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela, Bolivarian Republic of</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="VI">Virgin Islands, U.S.</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </Select>
              </FormGroup>
              <TextField id="address" label="Địa chỉ" className={classes.textField} value={company.address} onChange={event => this.handleFieldChange(event)} margin="normal"/>
              <TextField id="size" label="Quy mô" className={classes.textField} value={company.size} type="number" onChange={event => this.handleFieldChange(event)} margin="normal"/>
              <TextField id="typeVN" label="Loại hình hoạt động" className={classes.textField} value={company.typeVN} onChange={event => this.handleValidate(event,'vn')} margin="normal"/>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col3}>
                  <Select
                    native
                    id="workingDay1"
                    value={company.workingDay.split(',')[0]}
                    onChange={event => this.handleDayChange(event,0)}
                    title="Từ: "
                  >
                    {calendar.map((item, index) => (
                      <option key={index + 1} value={item}>
                        {language.VN.day[item]}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.col3}>
                  <Select
                    native
                    id="workingDay2"
                    value={company.workingDay.split(',')[1]}
                    onChange={event => this.handleDayChange(event,1)}
                    title="Đến :"
                  >
                    {calendar.map((item, index) => (
                      <option key={index + 1} value={item}>
                        {language.VN.day[item]}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.col3}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isOT"
                        checked={company.isOT}
                        onChange={(event)=>this.handleFieldChange(event)}
                      />
                    }
                    label="Có OT"
                  />
                </FormControl>
                <FormControl className={classes.col3}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isPartner"
                        checked={company.isPartner}
                        onChange={(event)=>this.handleFieldChange(event)}
                      />
                    }
                    label="Hiện ở đối tác"
                  />
                </FormControl>
              </FormGroup>
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.summaryVN_error}
              >
                {language.VN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={company.summaryVN}
                onModelChange={value =>
                  this.onEditorStateChange("summaryVN", value)
                }
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailVN_error}
              >{language.VN.detail}
              </FormLabel>
              {
                this.state.options['empty']!=='empty' && (
                  <FroalaEditor
                    tag="textarea"
                    config={editorConfig}
                    model={company.detailVN}
                    onModelChange={value =>
                      this.onEditorStateChange("detailVN", value)
                    }
                  />
                )
              }
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={event => this.handleCancel(event)}
                >
                  {language.VN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "vn")}
                >
                  {language.VN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.en && classes.hide
                )}
              >
                {language.EN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <TextField id="typeEN" label="Type of Company" className={classes.textField} value={company.typeEN} onChange={event => this.handleValidate(event,'en')} margin="normal"/>
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.summaryEN_error}
              >
                {language.EN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={company.summaryEN}
                onModelChange={value =>
                  this.onEditorStateChange("summaryEN", value)
                }
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailEN_error}
              >
                {language.EN.detail}
              </FormLabel>
              {
                this.state.options['empty']!=='empty' && (
                  <FroalaEditor
                    tag="textarea"
                    config={editorConfig}
                    model={company.detailEN}
                    onModelChange={value =>
                      this.onEditorStateChange("detailEN", value)
                    }
                  />
                )
              }
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={event => this.handleCancel(event)}
                >
                  {language.EN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "en")}
                >
                  {language.EN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
      </div>
    );
  }
}

CompanyDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
CompanyDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(CompanyDetail);
